
/* 初始化 */
body,
div,
ul,
li,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea,
select,
p,
dl,
dt,
dd,
a,
img,
button,
form,
table,
th,
tr,
td,
tbody,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    margin: 0;
    padding: 0;
}

ul,
ol,
li {
    list-style: none;
}

div {
    color: #333;
}

img {
    object-fit: cover;
}

.mina-color {
    color: #67C066;
}

.body{
    width: 100%;
    height: 100vh;
}

.w100{
    width: 100%;
}

.h100{
    height: 100%;
}

.bg-white {
    background-color: white;
}

.bg-gray {
    background-color: #F2F2F2;
}

.normal {
    color: #333333;
}

.lighter {
    color: #666666;
}

.muted {
    color: #999999;
}

.white {
    color: white;
}

/* 定义字体大小 */

.xxl {
    font-size: 18px;
}

.xl {
    font-size: 17px;
}

.lg {
    font-size: 16px;
}

.md {
    font-size: 15px;
}

.nr {
    font-size: 14px;
}

.sm {
    font-size: 13px;
}

.xs {
    font-size: 12px;
}

.xxs {
    font-size: 11px;
}

.fz8 {
    -webkit-transform: scale(0.8);
}

.bold {
    font-weight: bold;
}

.over-hidden {
    overflow: hidden;
}

.text-center {
    text-align: center;
}

/*单行溢出*/
.one-txt-cut {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/*多行溢出 手机端使用*/
.two-txt-cut {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.br5 {
    border-radius: 5px;
}

.br8 {
    border-radius: 8px;
}

.br10 {
    border-radius: 10px;
}

.br15 {
    border-radius: 15px;
}

.br20 {
    border-radius: 20px;
}

.br30 {
    border-radius: 30px;
}

.br60 {
    border-radius: 60px;
}

/******外边距原子样式******/
.mr5 {
    margin-right: 5px;
}

.mr10 {
    margin-right: 10px;
}

.mr20 {
    margin-right: 20px;
}

.ml5 {
    margin-left: 5px;
}

.ml10 {
    margin-left: 10px;
}

.ml20 {
    margin-left: 20px;
}

.mt5 {
    margin-top: 5px;
}

.mt10 {
    margin-top: 10px;
}

.mt20 {
    margin-top: 20px;
}

.mt30 {
    margin-top: 30px;
}

.mb5 {
    margin-bottom: 5px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb15 {
    margin-bottom: 15px;
}

.mb20 {
    margin-bottom: 20px;
}
.pd5 {
    padding: 5px;
}

.pd10 {
    padding: 10px;
}

.pd15 {
    padding: 15px;
}

.pd20 {
    padding: 20px;
}

.pd25 {
    padding: 25px;
}

.pd30 {
    padding: 30px;
}

.pw5 {
    padding:0 5px;
}

.pw10 {
    padding-left: 10px;
    padding-right: 10px;
}

.pw15 {
    padding:0 15px;
}

.pw20 {
    padding-left: 20px;
    padding-right: 20px;
}

.pw25 {
    padding:0 25px;
}

.pw30 {
    padding:0 30px;
}

.ph2{
    padding-top: 2px;
    padding-bottom: 2px;
}

.ph5 {
    padding-top: 5px;
    padding-bottom: 5px;
}

.ph10 {
    padding: 10px 0;
}

.ph15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.ph20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.ph25 {
    padding: 25px 0;
}

.ph30 {
    padding: 30px 0;
}

.pt5 {
    padding-top: 5px;
}

.pt10 {
    padding-top: 10px;
}

.pt15 {
    padding-top: 15px;
}

.pt20 {
    padding-top: 20px;
}

.pt25 {
    padding-top: 25px;
}

.pr5 {
    padding-right: 5px;
}

.pr10 {
    padding-right: 10px;
}

.pr15 {
    padding-right: 15px;
}

.pr20 {
    padding-right: 20px;
}

.pb5 {
    padding-bottom: 5px;
}

.pb10 {
    padding-bottom: 10px;
}

.pb15 {
    padding-bottom: 15px;
}

.pb20 {
    padding-bottom: 20px;
}

.pl5 {
    padding-left: 5px;
}

.pl10 {
    padding-left: 10px;
}

.pl15 {
    padding-left: 15px;
}

.pl20 {
    padding-left: 20px;
}











/******布局样式******/
.wrap {
    flex-wrap: wrap;
}

.flex {
  display: flex;
}

.flex1 {
  flex: 1;
}


.center {
    justify-content: center;
    align-items: center;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.row-start {
    @extend .row;
    align-items: flex-start;
}

.row-center {
    @extend .row;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.row-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.row-around {
    @extend .row;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.row-end {
    @extend .row;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.column {
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
}

.column-start {
    @extend .column;
    justify-content: flex-start;
}

.column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.column-around {
    @extend .column;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.column-between {
    @extend .column;
    align-items: center;
    justify-content: space-between;
}

.container {
    @extend .column;
}

.position-re{
    position: relative
}

.position-ab{
    position: absolute
}

/******图标******/

@mixin icon-image($size) {
    min-height: $size;
    min-width: $size;
    height: $size;
    width: $size;
    vertical-align: middle;
}

.icon-xs {
    @include icon-image(14px);
}

.icon-sm {
    @include icon-image(15px);
}

.icon {
    @include icon-image(22px);
}

.icon-lg {
    @include icon-image(26px);
}

.icon-xl {
    @include icon-image(32px);
}

.icon-xxl {
    @include icon-image(60px);
}

.img-null {
    width: 150px;
    height: 150px;
}

button {
    outline: none;
    border: none;
    background-color: transparent;
}

/* 按钮大小 */
button[size="xs"] {
    line-height: 29px;
    height: 29px;
    font-size: 13px;
    padding: 0 15px;
}

button[size="sm"] {
    line-height: 31px;
    height: 31px;
    font-size: 14px;
    padding: 0 15px;
}

button[size="md"] {
    line-height: 35px;
    height: 35px;
    font-size: 15px;
    padding: 0 15px;
}

button[size="lg"] {
    line-height: 42px;
    height: 42px;
    font-size: 16px;
    padding: 0 15px;
}

/* 中划线 */
.line-through {
    text-decoration: line-through;
}

#app .van-fade-enter-active,
#app .van-fade-leave-active {
    width: 100%;
    position: absolute;
    top: 0;
}

#app .van-search {
    padding: 7.5px 10px;
}
#app .van-tabs .van-tabs__wrap,
#app .van-tabs .van-tab {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
}


.van-tabs .van-hairline--top-bottom:after {
    border-width: 0;
}

.hidden-scrollbar::-webkit-scrollbar {
    display: none;
}

.van-overlay {
    width: 100vw;
    height: 100vh;
}


.coupon-style {
    width: 160px;
    height: 100px;
    background: linear-gradient(0.25turn, #fff, #fee7e8), radial-gradient(circle at 11px 4px, transparent 6px, #fee7e8 6px) top left, radial-gradient(circle at 10px 56px, transparent 6px, #fee7e8 6px) bottom left, linear-gradient(0.25turn, #fee7e8, #fee7e8);
    background-size: 120px 82px, 20px 50px, 20px 57px, 32px 84px;
    background-repeat: no-repeat;
    background-position: 0px 0px,120px -4px, 120px 26px, 140px 0px;
  }

.border-red{
    border: 1px solid #FF541F;
}

.border-green{
    border: 1px solid #67C066;
}

.event-border {
    border: 2px solid #4bb6c0;
    background-color: #ffe9d1;
    border-radius: 8px;
}
